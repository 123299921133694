import React from 'react';

import { identity } from '../../utils/general';
import { isValidLat, isValidLon } from '../../utils/geo_json';
import { PGN, PGN_VALUE } from './consts';
import { isValidPgnValue } from './utils'
import { dcTypeToStr, fluidTypeToStr, temperatureToStr } from './meta';
import ColorDeterminator from '../../utils/color_determinator';
import {
  CHART_TYPE,
  DEG2RAD,
  LONGITUDE_MAX,
  LONGITUDE_MIN,
  NODATA,
  UNIT_DEG,
  UNIT_PERC
} from '../../utils/consts';
import {
  tempSym,
  depthSym,
  speedSym,
  depthInUnits,
  tempInUnits,
  speedInUnits,
  flowInUnits,
  flowSym,
  voltageToStr,
  currentToStr,
  latToStr,
  lonToStr,
  speedToStr,
  flowToStr,
  tempToStr,
  crsToStr,
  depthToStr,
  eUnitStyle,
  rpmToStr,
  speedInIsoUnits,
  flowInIsoUnits,
  tempInIsoUnits,
  depthInIsoUnits,
  durationToStr,
  distSym,
  distInUnits,
  distInIsoUnits,
  distToStr
} from '../../utils/units';

import { ReactComponent as EngineSvg } from '../../assets/icons/pgn/engine.svg';
import { ReactComponent as FluidSvg } from '../../assets/icons/pgn/fluid.svg';
import { ReactComponent as BatterySvg } from '../../assets/icons/pgn/battery.svg';
import { ReactComponent as WaterDepthSvg } from '../../assets/icons/pgn/water_depth.svg';
import { ReactComponent as TemperatureSvg } from '../../assets/icons/pgn/temperature.svg';
import { ReactComponent as RudderSvg } from '../../assets/icons/pgn/rudder.svg';
import { ReactComponent as GaugeSvg } from '../../assets/icons/pgn/gauge.svg';
import { ReactComponent as StateOfHealthSvg } from '../../assets/icons/pgn/state_of_health.svg';
import { ReactComponent as ChargeSvg } from '../../assets/icons/pgn/charge.svg';
import { ReactComponent as CourseSvg } from '../../assets/icons/pgn/course.svg';
import { ReactComponent as FuelSvg } from '../../assets/icons/pgn/fuel.svg';
import { ReactComponent as PositionSvg } from '../../assets/icons/pgn/position.svg';
import { ReactComponent as EngineTrimSvg } from '../../assets/icons/pgn/engine_trim.svg';
import { ReactComponent as DistanceSvg } from '../../assets/icons/pgn/distance.svg';

const toUnit = (val, unit, eUnit) => (isValidPgnValue(val) ? val : '---') + (eUnit !== eUnitStyle.EUS_None ? unit : '');

/**
 * PGN_PROPERTY: {
 *   key: string, // Identifier of data provided by PGN
 *   min: number?, // Minimum value (will be null if N/A)
 *   max: number?, // Maximum value (will be null if N/A)
 *   label: string, // Human readable identifier
 *   shortLabel: string, // Shrortened human readable label for data
 *   image: Node, // An image used to represent the data
 *   colorDeterminator: ColorDeterminator?, // 'ColorDeterminator' class instance (will be null if not applicable)
 *   charting: {
 *     type: string // Chart type
 *   },
 *   getLabel: (pgnMeta?: Object = null, short?: boolean = false)
 *   getUserUnit: () => string // Returns string representing unit
 *   convertToUserUnit: (number) => number  // Converts the given value to the users selected unit
 *   valueToStr: (number, ?eUnitStyle) => string // Converts the given value to an end user string representation
 * }
 */

/**
 * Keys of individual PGNs and their descriptions.
 */
export const pgnDefinitions = [{
  label: 'Detailed battery status',
  PGN: PGN.LXM_IOT_DC_STATUS_2,
  properties: [{
    key: PGN_VALUE.LXM_IOT_DC_STATUS_2__A,
    min: null,
    max: null,
    label: 'Current',
    shortLabel: 'Current',
    image: <ChargeSvg className='lxnavThemedSvg' />,
    colorDeterminator: null,
    charting: { type: CHART_TYPE.LINE },
    getLabel: (pgnMeta = null, short = false) => {
      const type = dcTypeToStr((pgnMeta || {}).DCTypeID, short);
      return !!type ? `${type} current` : 'Current';
    },
    getUserUnit: () => 'A',
    convertToUserUnit: identity,
    convertFromUserUnit: identity,
    valueToStr: currentToStr
  }, {
    key: PGN_VALUE.LXM_IOT_DC_STATUS_2__V,
    min: null,
    max: null,
    label: 'Voltage',
    shortLabel: 'Voltage',
    image: <ChargeSvg className='lxnavThemedSvg' />,
    colorDeterminator: null,
    charting: { type: CHART_TYPE.LINE },
    getLabel: (pgnMeta = null, short = false) => {
      const type = dcTypeToStr((pgnMeta || {}).DCTypeID, short);
      return !!type ? `${type} voltage` : 'Voltage';
    },
    getUserUnit: () => 'V',
    convertToUserUnit: identity,
    convertFromUserUnit: identity,
    valueToStr: voltageToStr
  }, {
    key: PGN_VALUE.LXM_IOT_DC_STATUS_2__TRM,
    min: null,
    max: null,
    label: 'Remaining time',
    shortLabel: 'Rem. time',
    image: <BatterySvg className='lxnavThemedSvg' />,
    colorDeterminator: null,
    charting: { type: CHART_TYPE.LINE },
    getLabel: (pgnMeta = null, short = false) => short ? 'Rem. time' : 'Remaining time',
    getUserUnit: () => null,
    convertToUserUnit: identity,
    convertFromUserUnit: identity,
    valueToStr: (val, eUnit = eUnitStyle.EUS_Short) => durationToStr(
      isValidPgnValue(val) ? val * 60 : NODATA,
      eUnit
    )
  }, {
    key: PGN_VALUE.LXM_IOT_DC_STATUS_2__RNG,
    min: null,
    max: null,
    label: 'Range',
    shortLabel: 'Range',
    image: <DistanceSvg className='lxnavThemedSvg' />,
    colorDeterminator: null,
    charting: { type: CHART_TYPE.NONE },
    getLabel: (pgnMeta = null, short = false) => short ? 'Range' : 'Range',
    getUserUnit: distSym,
    convertToUserUnit: distInUnits,
    convertFromUserUnit: distInIsoUnits,
    valueToStr: (val, eUnit = eUnitStyle.EUS_Short) => distToStr(val, undefined, eUnit)
  }]
}, {
  label: 'Basic positional data',
  PGN: PGN.LXM_IOT_GNSS_DATA_1,
  properties: [{
    key: PGN_VALUE.LXM_IOT_GNSS_DATA_1__LAT,
    min: -90,
    max: 90,
    label: 'Latitude',
    shortLabel: 'Lat.',
    image: <PositionSvg className='lxnavThemedSvg' />,
    colorDeterminator: null,
    charting: { type: CHART_TYPE.NONE },
    getLabel: (pgnMeta = null, short = false) => short ? 'Lat.' : 'Latitude',
    getUserUnit: () => UNIT_DEG,
    convertToUserUnit: identity,
    convertFromUserUnit: identity,
    valueToStr: (val, eUnit = eUnitStyle.EUS_Short) => latToStr(isValidLat(val) ? val * DEG2RAD : NODATA)
  }, {
    key: PGN_VALUE.LXM_IOT_GNSS_DATA_1__LON,
    min: LONGITUDE_MIN,
    max: LONGITUDE_MAX,
    label: 'Longitude',
    shortLabel: 'Lon.',
    image: <PositionSvg className='lxnavThemedSvg' />,
    colorDeterminator: null,
    charting: { type: CHART_TYPE.NONE },
    getLabel: (pgnMeta = null, short = false) => short ? 'Lon.' : 'Longitude',
    getUserUnit: () => UNIT_DEG,
    convertToUserUnit: identity,
    convertFromUserUnit: identity,
    valueToStr: (val, eUnit = eUnitStyle.EUS_Short) => lonToStr(isValidLon(val) ? val * DEG2RAD : NODATA)
  }]
}, {
  label: 'Detailed positional data',
  PGN: PGN.LXM_IOT_GNSS_DATA_2,
  properties: [{
    key: PGN_VALUE.LXM_IOT_GNSS_DATA_2__COG,
    min: 0,
    max: 360,
    label: 'Course over ground',
    shortLabel: 'COG',
    image: <CourseSvg className='lxnavThemedSvg' />,
    colorDeterminator: null,
    charting: { type: CHART_TYPE.LINE },
    getLabel: (pgnMeta = null, short = false) => short ? 'COG' : 'Course over ground',
    getUserUnit: () => UNIT_DEG,
    convertToUserUnit: identity,
    convertFromUserUnit: identity,
    valueToStr: (val, eUnit = eUnitStyle.EUS_Short) => crsToStr(
      isValidPgnValue(val) ? val * DEG2RAD : NODATA,
      false,
      NODATA,
      null,
      eUnit
    )
  }, {
    key: PGN_VALUE.LXM_IOT_GNSS_DATA_2__SOG,
    min: null,
    max: null,
    label: 'Speed over ground',
    shortLabel: 'SOG',
    image: <GaugeSvg className='lxnavThemedSvg' />,
    colorDeterminator: null,
    charting: { type: CHART_TYPE.LINE },
    getLabel: (pgnMeta = null, short = false) => short ? 'SOG' : 'Speed over ground',
    getUserUnit: speedSym,
    convertToUserUnit: speedInUnits,
    convertFromUserUnit: speedInIsoUnits,
    valueToStr: (val, eUnit = eUnitStyle.EUS_Short) => speedToStr(val, undefined, eUnit)
  }]
}, {
  label: 'Fluid',
  PGN: PGN.LXM_IOT_FLUID,
  properties: [{
    key: PGN_VALUE.LXM_IOT_FLUID__LVL,
    min: 0,
    max: 100,
    label: 'Fluid level',
    shortLabel: 'fluid l.',
    image: <FluidSvg className='lxnavThemedSvg' />,
    colorDeterminator: null,
    charting: { type: CHART_TYPE.BAND_BAR },
    getLabel: (pgnMeta = null, short = false) => fluidTypeToStr((pgnMeta || {}).FluidTypeID, short),
    getUserUnit: () => UNIT_PERC,
    convertToUserUnit: identity,
    convertFromUserUnit: identity,
    valueToStr: (val, eUnit = eUnitStyle.EUS_Short) => toUnit(val, UNIT_PERC, eUnit)
  }]
}, {
  label: 'Basic engine data',
  PGN: PGN.LXM_IOT_ENGINE_DATA_1,
  properties: [{
    key: PGN_VALUE.LXM_IOT_ENGINE_DATA_1__RPM,
    min: null,
    max: null,
    label: 'Engine RPM',
    shortLabel: 'Eng. RPM',
    image: <EngineSvg className='lxnavThemedSvg' />,
    colorDeterminator: null,
    charting: { type: CHART_TYPE.LINE },
    getLabel: (pgnMeta = null, short = false) => short ? 'Eng. RMP' : 'Engine RPM',
    getUserUnit: () => null,
    convertToUserUnit: identity,
    convertFromUserUnit: identity,
    valueToStr: rpmToStr
  }, {
    key: PGN_VALUE.LXM_IOT_ENGINE_DATA_1__FUEL_RATE,
    min: null,
    max: null,
    label: 'Fuel rate',
    shortLabel: 'Fuel rate',
    image: <FuelSvg className='lxnavThemedSvg' />,
    colorDeterminator: null,
    charting: { type: CHART_TYPE.LINE },
    getLabel: (pgnMeta = null, short = false) => 'Fuel rate',
    getUserUnit: flowSym,
    convertToUserUnit: flowInUnits,
    convertFromUserUnit: flowInIsoUnits,
    valueToStr: (val, eUnit = eUnitStyle.EUS_Short) => flowToStr(val, undefined, eUnit)
  }]
}, {
  label: 'Detailed engine data',
  PGN: PGN.LXM_IOT_ENGINE_DATA_2,
  properties: [{
    key: PGN_VALUE.LXM_IOT_ENGINE_DATA_2__TEMP,
    min: null,
    max: null,
    label: 'Engine temperature',
    shortLabel: 'Eng. temp',
    image: <TemperatureSvg className='lxnavThemedSvg' />,
    colorDeterminator: null,
    charting: { type: CHART_TYPE.LINE },
    getLabel: (pgnMeta = null, short = false) => short ? 'Eng. temp.' : 'Engine temperature',
    getUserUnit: tempSym,
    convertToUserUnit: tempInUnits,
    convertFromUserUnit: tempInIsoUnits,
    valueToStr: (val, eUnit = eUnitStyle.EUS_Short) => tempToStr(val, undefined, eUnit)
  }, {
    key: PGN_VALUE.LXM_IOT_ENGINE_DATA_2__TRIM,
    min: 0,
    max: 100,
    label: 'Engine trim level',
    shortLabel: 'Eng. trim',
    image: <EngineTrimSvg className='lxnavThemedSvg' />,
    colorDeterminator: null,
    charting: { type: CHART_TYPE.LINE },
    getLabel: (pgnMeta = null, short = false) => short ? 'Eng. trim' : 'Engine trim',
    getUserUnit: () => UNIT_PERC,
    convertToUserUnit: identity,
    convertFromUserUnit: identity,
    valueToStr: (val, eUnit = eUnitStyle.EUS_Short) => toUnit(val, UNIT_PERC, eUnit)
  }]
}, {
  label: 'Temperature',
  PGN: PGN.LXM_IOT_TEMPERATURE,
  properties: [{
    key: PGN_VALUE.LXM_IOT_TEMPERATURE__TEMP,
    min: null,
    max: null,
    label: 'Temperature',
    shortLabel: 'Temp.',
    image: <TemperatureSvg className='lxnavThemedSvg' />,
    colorDeterminator: null,
    charting: { type: CHART_TYPE.LINE },
    getLabel: (pgnMeta = null, short = false) => temperatureToStr((pgnMeta || {}).TempTypeID, short),
    getUserUnit: tempSym,
    convertToUserUnit: tempInUnits,
    convertFromUserUnit: tempInIsoUnits,
    valueToStr: (val, eUnit = eUnitStyle.EUS_Short) => tempToStr(val, undefined, eUnit)
  }]
}, {
  label: 'Basic battery status',
  PGN: PGN.LXM_IOT_DC_STATUS_1,
  properties: [{
    key: PGN_VALUE.LXM_IOT_DC_STATUS_1__SOH,
    min: 0,
    max: 100,
    label: 'Battery health',
    shortLabel: 'Batt. health',
    image: <StateOfHealthSvg className='lxnavThemedSvg' />,
    colorDeterminator: null,
    charting: { type: CHART_TYPE.AREA },
    getLabel: (pgnMeta = null, short = false) => short ? 'Batt. health' : 'Battery health',
    getUserUnit: () => UNIT_PERC,
    convertToUserUnit: identity,
    convertFromUserUnit: identity,
    valueToStr: (val, eUnit = eUnitStyle.EUS_Short) => toUnit(val, UNIT_PERC, eUnit)
  }, {
    key: PGN_VALUE.LXM_IOT_DC_STATUS_1__SOC,
    min: 0,
    max: 100,
    label: 'Charge',
    shortLabel: 'Charge',
    image: <BatterySvg className='lxnavThemedSvg' />,
    colorDeterminator: new ColorDeterminator([
      { value: 0, color: { r: 201, g: 26, b: 26 } },
      { value: 50, color: { r: 236, g: 241, b: 0 } },
      { value: 100, color: { r: 143, g: 211, b: 74 } },
    ]),
    charting: { type: CHART_TYPE.AREA },
    getLabel: (pgnMeta = null, short = false) => short ? 'Charge' : 'State of charge',
    getUserUnit: () => UNIT_PERC,
    convertToUserUnit: identity,
    convertFromUserUnit: identity,
    valueToStr: (val, eUnit = eUnitStyle.EUS_Short) => toUnit(val, UNIT_PERC, eUnit)
  }, {
    key: PGN_VALUE.LXM_IOT_DC_STATUS_1__TEMP,
    min: null,
    max: null,
    label: 'Battery temperature',
    shortLabel: 'Batt. temp.',
    image: <TemperatureSvg className='lxnavThemedSvg' />,
    colorDeterminator: null,
    charting: { type: CHART_TYPE.LINE },
    getLabel: (pgnMeta = null, short = false) => short ? 'Batt. temp.' : 'Battery temperature',
    getUserUnit: tempSym,
    convertToUserUnit: tempInUnits,
    convertFromUserUnit: tempInIsoUnits,
    valueToStr: (val, eUnit = eUnitStyle.EUS_Short) => tempToStr(val, undefined, eUnit)
  }]
}, {
  label: 'Water depth',
  PGN: PGN.LXM_IOT_WATER_DEPTH,
  properties: [{
    key: PGN_VALUE.LXM_IOT_WATER_DEPTH__DEPTH,
    min: null,
    max: null,
    label: 'Water depth',
    shortLabel: 'Depth',
    image: <WaterDepthSvg className='lxnavThemedSvg' />,
    colorDeterminator: null,
    charting: { type: CHART_TYPE.AREA },
    getLabel: (pgnMeta = null, short = false) => 'Water depth',
    getUserUnit: depthSym,
    convertToUserUnit: depthInUnits,
    convertFromUserUnit: depthInIsoUnits,
    valueToStr: (val, eUnit = eUnitStyle.EUS_Short) => depthToStr(val, undefined, eUnit)
  }]
}, {
  label: 'Reference speed',
  PGN: PGN.LXM_IOT_WREF_SPD,
  properties: [{
    key: PGN_VALUE.LXM_IOT_WREF_SPD__SPD,
    min: null,
    max: null,
    label: 'Speed',
    shortLabel: 'Speed',
    image: <GaugeSvg className='lxnavThemedSvg' />,
    colorDeterminator: null,
    charting: { type: CHART_TYPE.LINE },
    getLabel: (pgnMeta = null, short = false) => 'Speed',
    getUserUnit: speedSym,
    convertToUserUnit: speedInUnits,
    convertFromUserUnit: speedInIsoUnits,
    valueToStr: (val, eUnit = eUnitStyle.EUS_Short) => speedToStr(val, undefined, eUnit)
  }]
}, {
  label: 'Rudder',
  PGN: PGN.LXM_IOT_RUDDER,
  properties: [{
    key: PGN_VALUE.LXM_IOT_RUDDER__POS,
    min: null,
    max: null,
    label: 'Rudder position',
    shortLabel: 'Rudder pos.',
    image: <RudderSvg className='lxnavThemedSvg' />,
    colorDeterminator: null,
    charting: { type: CHART_TYPE.LINE },
    getLabel: (pgnMeta = null, short = false) => short ? 'Rudder pos.' : 'Rudder position',
    getUserUnit: () => UNIT_DEG,
    convertToUserUnit: identity,
    convertFromUserUnit: identity,
    valueToStr: (val, eUnit = eUnitStyle.EUS_Short) => toUnit(val, UNIT_DEG, eUnit)
  }]
}];

const pgnLookup = {};
for (const pgnDef of pgnDefinitions) {
  const { PGN, properties } = pgnDef;

  const propertyLookup = {};
  for (const propDef of properties) {
    const { key } = propDef;
    propertyLookup[key] = propDef;
  }

  pgnLookup[PGN] = {
    definition: pgnDef,
    properties: propertyLookup
  };
}

/**
 *  Finds the description object of the given PGN.
 *  return object: {
 *    label: string // human readable label of PGN
 *    PGN: number // PGN identifier
 *    properties: array // array of PGN_PROPERTY OBJECT
 *  }
 */
export const getPgnDefinition = pgn => (pgnLookup[pgn] || {}).definition;

/**
 *  Returns the array of properties for the given PGN. Returns an array of PGN_PROPERTY.
 */
export const getPgnPropertyDefinitions = pgn => (getPgnDefinition(pgn) || {}).properties;

/**
 *  Finds the PGN_PROPERTY for the PGN and option key.
 */
export const getPgnPropertyDefinition = (pgn, propertyKey) => ((pgnLookup[pgn] || {}).properties || {})[propertyKey];
