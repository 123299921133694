import { isNil } from 'lodash';
import { getUserDetails, signIn, signOut } from '../../api/account_api';
import HttpException from '../../api/base/http_exception';
import { appEvents } from '../../events/app_events';
import InvalidUserError from './user/error/invalid_user_error';
import User from './user/user';
import UserSettings from './user/user_settings';

/**
 * Class used to manage user's authentication.
 */
class AuthManager {
  constructor() {
    this._user = null;

    this.setUser = this.setUser.bind(this);
    this.reloadUser = this.reloadUser.bind(this);
    this.signIn = this.signIn.bind(this);
    this.signOut = this.signOut.bind(this);
  }

  /**
   * Returns the current user if authenticated or null otherwise.
   * 
   * @returns {null|User} the authenticated user
   */
  get currentUser() {
    return this._user;
  }

  /**
   * Returns true if authenticated and false otherwise.
   */
  get isSignedIn() {
    return !!this._user && !this._user.isDisabled;
  }

  /**
   * Sets the authenicated user and invokes the user_changed event if the passed user isn't equal to the current.
   * Useful for when the user's account is updated.
   * 
   * @throws {InvalidUserError} the passed object is not valid
   * 
   * @param {User|null} user the user to set
   */
  setUser(user) {
    if (!!user && !AuthManager.isValid(user)) {
      throw new InvalidUserError(user, 'User is not valid');
    }

    const isUserSame = (!user && !this._user)
      || (
        !!user
        && !!this._user
        && this._user.isEqual(user)
      );

    if (isUserSame)
      return;

    this._user = user;
    appEvents.user_changed.dispatch(this._user);
  }

  /**
   * Retrieves the user from the server and sets them as the authenticated user.
   * 
   * @throws {HttpException}
   * @throws {InvalidUserError} the passed object is not valid
   */
  async reloadUser() {
    let details;
    try {
      details = await getUserDetails();
    } catch (e) {
      if (e instanceof HttpException && e.statusCode === 401) {
        this.setUser(null);
        return;
      } else {
        throw e;
      }
    }

    if (!!details) {
      const newUser = User.createFromApiUser(details);
      this.setUser(newUser);
    } else {
      throw new InvalidUserError("user's details are null");
    }
  }

  /**
   * Attempts to authenticate the given user. If successful, the identified user will be set as the authenticated user.
   * If a user is already authenticated, call this method will have no effect.
   * 
   * @throws {HttpException}
   * @throws {InvalidUserError} the passed object is not valid
   */
  async signIn(email, password, rememberMe) {
    if (this.isSignedIn)
      return;

    await signIn(email, password, rememberMe);
    await this.reloadUser();
  }

  /**
   * Signs-out the currenty authenticated user. If no user is authenticated, calling this method will have no effect.
   * 
   * @throws {HttpException}
   */
  async signOut() {
    if (!this.isSignedIn)
      return;

    try {
      await signOut();
    } catch (e) {
      if (!(e instanceof HttpException) || e.statusCode !== 401)
        throw e;
    }

    this._user = null;
    appEvents.user_changed.dispatch(null);
  }

  /**
   * Returns true if the passed paramemter is a User instance and is considered valid. 
   * 
   * @param {*} user 
   * @returns {boolean}
   */
  static isValid(user) {
    if (!(user instanceof User))
      return false;

    return !isNil(user.id)
      && !isNil(user.dataBalanceId)
      && !isNil(user.username)
      && !isNil(user.email)
      && Array.isArray(user.roles)
      && user.settings instanceof UserSettings;
  }
}

export default AuthManager;
