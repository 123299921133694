import React from 'react';
import moment from 'moment';

import {
  distToStr,
  volumeToStr,
  distSym,
  distInUnits,
  volumeInUnits,
  volumeSym,
  eUnitStyle,
  fuelEcoSym,
  fuelEcoInUnits,
  fuelEcoToStr
} from '../units';
import { identity } from '../general';

import { ReactComponent as DistanceSvg } from '../../assets/icons/pgn/distance.svg';
import { ReactComponent as GaugeSvg } from '../../assets/icons/pgn/gauge.svg';
import { ReactComponent as FuelSvg } from '../../assets/icons/pgn/fuel.svg';
import { SEC2MSEC } from '../consts';
import { isValidPgnValue } from '../../core/pgn/utils';

// Stat types
export const VESSEL_STAT__TRIPS_CNT = 'TripCnt';
export const VESSEL_STAT__TOTAL_DIST = 'TotDist';
export const VESSEL_STAT__AVG_DIST = 'AvgDist';
export const VESSEL_STAT__TOTAL_FUEL = 'TotFuel';
export const VESSEL_STAT__AVG_FUEL_ECO = 'AvgFuelEco'
export const VESSEL_STAT__TOTAL_ONLINE = 'TOnl';

const statisticsDefinitions = [{
  label: 'Trips completed',
  shortLabel: 'Trips compl.',
  key: VESSEL_STAT__TRIPS_CNT,
  image: <DistanceSvg className='lxnavThemedSvg' />,
  getUserUnit: () => null,
  convertToUserUnit: identity,
  valueToStr: (val, eUnit = eUnitStyle.EUS_Short) => isValidPgnValue(val) ? val.toString() : '---'
}, {
  label: 'Total distance covered',
  shortLabel: 'Total dist.',
  key: VESSEL_STAT__TOTAL_DIST,
  image: <DistanceSvg className='lxnavThemedSvg' />,
  getUserUnit: distSym,
  convertToUserUnit: distInUnits,
  valueToStr: (val, eUnit = eUnitStyle.EUS_Short) => distToStr(val, undefined, eUnit)
}, {
  label: 'Average trip distance',
  shortLabel: 'Avg. trip dist.',
  key: VESSEL_STAT__AVG_DIST,
  image: <DistanceSvg className='lxnavThemedSvg' />,
  getUserUnit: distSym,
  convertToUserUnit: distInUnits,
  valueToStr: (val, eUnit = eUnitStyle.EUS_Short) => distToStr(val, undefined, eUnit)
}, {
  label: 'Total fuel used',
  shortLabel: 'Total fuel used',
  key: VESSEL_STAT__TOTAL_FUEL,
  image: <FuelSvg className='lxnavThemedSvg' />,
  getUserUnit: volumeSym,
  convertToUserUnit: volumeInUnits,
  valueToStr: (val, eUnit = eUnitStyle.EUS_Short) => volumeToStr(val, undefined, eUnit)
}, {
  label: 'Average fuel economy',
  shortLabel: 'Avg. fuel eco.',
  key: VESSEL_STAT__AVG_FUEL_ECO,
  image: <FuelSvg className='lxnavThemedSvg' />,
  getUserUnit: fuelEcoSym,
  convertToUserUnit: fuelEcoInUnits,
  valueToStr: (val, eUnit = eUnitStyle.EUS_Short) => fuelEcoToStr(val, undefined, undefined, undefined, eUnit)
}, {
  label: 'Total time online',
  shortLabel: 'Time online',
  key: VESSEL_STAT__TOTAL_ONLINE,
  image: <GaugeSvg className='lxnavThemedSvg' />,
  getUserUnit: () => null,
  convertToUserUnit: identity,
  valueToStr: (val, eUnit = eUnitStyle.EUS_Short) => isValidPgnValue(val) ? moment.duration(val * SEC2MSEC).humanize() : '---'
}];

const lookupObj = {};
for (const statDef of statisticsDefinitions) {
  const { key } = statDef;

  lookupObj[key] = {
    definition: statDef,
  };
}

export const getStatisticDefinition = key => (lookupObj[key] || {}).definition;
